import { ComponentRef, inject, Injectable, ViewContainerRef } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { filter, finalize } from 'rxjs/operators'
import { ProbabilityInfo } from '../../modules/summary/summary.interface'
import { BingliAuthService } from '@mybingli/security-service'
import { of } from 'rxjs'

export interface IDialogInformation {
  title?: string
  message: string
  confirmBtnTranslation?: string
  declineBtnTranslation?: string
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private authService = inject(BingliAuthService)
  private _viewContainerRef?: ViewContainerRef

  setRootViewContainerRef(viewContainerRef?: ViewContainerRef): void {
    this._viewContainerRef = viewContainerRef
  }

  async createChartModal(options: IDialogInformation, probabilityInfo: ProbabilityInfo) {
    const ChartDialogComponent = await import('../../shared/dialog/component/chart-dialog/chart-dialog.component').then(
      m => m.ChartDialogComponent
    )
    return this.createModal(ChartDialogComponent, options, { probabilityInfo })
  }

  async createDialogModal(options: IDialogInformation) {
    const DialogComponent = await import('../../shared/dialog/dialog.component').then(m => m.DialogComponent)
    return this.createModal(DialogComponent, options)
  }

  async showErrorModal(info: IDialogInformation, status = 200) {
    const modalInfo = await this.createDialogModal(info)
    modalInfo.pipe(filter((data: boolean) => data)).subscribe(() => {
      if ([401, 403].includes(status)) {
        return this.authService.logout()
      }
    })
  }

  private createModal(component: any, options: IDialogInformation, meta?: { [key: string]: any }) {
    if (this._viewContainerRef) {
      const componentRef: ComponentRef<any> = this._viewContainerRef.createComponent(component)
      componentRef.instance.options = options
      if (meta && Object.keys(meta).length) {
        Object.keys(meta).forEach(key => (componentRef.instance[key] = meta[key]))
      }

      return componentRef.instance.afterClosed().pipe(
        takeUntilDestroyed(componentRef.instance.destroyRef),
        finalize(() => componentRef.destroy())
      )
    }
    return of(false)
  }
}
