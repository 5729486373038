import { Gender, SortOrderLegacy } from 'src/app/shared/data/enums'

import { Country } from 'src/app/shared/data/models/client.model'
import { Gender as GqlGender } from 'src/app/graphql/patients/patients.generated'

export interface PatientUserInfo {
  email: string
  id: string
  language: string | null
  phone: PatientPhone | null
}

export interface PatientData {
  id: string
  firstName: string
  lastName: string
  middleName: string | null
  gender: GqlGender | Gender
  birthdate: string
  address: PatientAddress | null
  language: string | null
  email: string | null
  phone: PatientPhone | null
}

export interface PatientFormData {
  birthDate: string | null
  gender: string | null
  language?: string | null
  email: string | null
  firstName: string | null
  lastName: string | null
  phone: PatientPhone | null
}

export interface PatientAddress {
  street: string
  number: number
  zipCode: string
  city: string
  countryId: string
  box?: string
  addressExtra1?: string
  addressExtra2?: string
}

export interface PatientPhone {
  phoneNumber: string
  phoneCountryId: string
  phoneType: number
  isWorkPhone: boolean
}

export interface GqlPatientPhone {
  phoneNumber: string
  id: string
  country: Country
}

export enum InviteType {
  email = 'email',
  sms = 'sms',
  url = 'url'
}

export enum CommunicationServiceTypes {
  Email = 10,
  Sms = 20
}

export interface KioskCode {
  code: string
  expiration: string
}

export interface ActiveSort {
  sortColumnName: string
  sortOrder: SortOrderLegacy
}

export interface Pagination {
  pageIndex: number
  pageSize: number
}

export interface InterviewFilterOptions {
  rov: string[]
  actionTypeIds: string[]
  alerts: string[]
}

export enum InterviewFilterType {
  containsTextFilter = 'containsTextFilter',
  multipleChoiceFilter = 'multipleChoiceFilter',
  timeRangeFilter = 'timeRangeFilter',
  exactBirthdateFilter = 'exactBirthdateFilter',
  nameFilter = 'nameFilter',
  patientDataFilter = 'patientDataFilter'
}

export interface DashboardColumn {
  key: string
  label: string
  width?: number
  filterType?: InterviewFilterType
  options?: { value: string; label: string }[]
}
export interface InterviewFilter {
  type: InterviewFilterType
  label: string | null
  value: string
  columnName: string
  columnLabel: string
}

export interface QueryVars {
  [key: string]: string | string[] | number | number[]
}

export interface TablePreferences {
  sortColumnName?: string
  sortOrder?: number // SortOrder;
  columns: ColumnPreference[]
}

export interface ColumnPreference {
  columnName: string
  position: number
  width: number
}

export interface DashboardTableFilterDialogData {
  columnsAvailableForFiltering: DashboardColumn[]
  activeFilters: InterviewFilter[]
}

export interface RovHeader {
  code: string
  order: number
}

export interface RovHeaderUpdate {
  rovCode: string
  headers: RovHeader[]
}

export interface ClinicalNote {
  id: string
  text: string
  date: string
  user: User
}

export interface User {
  id: string
  email?: string
  firstName?: string
  lastName?: string
}
