import { ActiveSort, DashboardColumn, InterviewFilterType, TablePreferences } from './dashboard.model'
import { InterviewStatus, ViewName } from './dashboard.enums'
import { SortOrderLegacy } from 'src/app/shared/data/enums'
import { CareEpisodeCommunicationType } from '../../summary/summary.interface'
import { PAGINATION_LIMIT } from '@shared/data/constants'

export const defaultInterviewsColumns: DashboardColumn[] = [
  {
    key: 'surveyDate',
    label: 'date_time',
    filterType: InterviewFilterType.timeRangeFilter
  },
  {
    key: 'patient',
    label: 'l_patient',
    filterType: InterviewFilterType.patientDataFilter
  },
  {
    key: 'reasonOfVisit',
    label: 'rov',
    filterType: InterviewFilterType.multipleChoiceFilter,
    options: []
  },
  {
    key: 'status',
    label: 'status',
    filterType: InterviewFilterType.multipleChoiceFilter,
    options: [
      { value: 'New', label: InterviewStatus.new },
      { value: 'InProgress', label: InterviewStatus.inProgress },
      { value: 'Completed', label: InterviewStatus.finished }
    ]
  },
  {
    key: 'action',
    label: 'actions',
    filterType: InterviewFilterType.multipleChoiceFilter,
    options: []
  },
  {
    key: 'alerts',
    label: 'l_alerts',
    filterType: InterviewFilterType.multipleChoiceFilter,
    options: []
  },
  {
    key: 'triageScore',
    label: 'triage_score',
    filterType: InterviewFilterType.multipleChoiceFilter,
    options: [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
      { value: '999', label: 'l_n_a' }
    ]
  }
]

export const menuColumn: DashboardColumn = { key: 'menu', label: '' }

export const defaultPatientsColumns: DashboardColumn[] = [
  {
    key: 'birthDate',
    label: 'birthday',
    filterType: InterviewFilterType.exactBirthdateFilter
  },
  {
    key: 'name',
    label: 'name_all',
    filterType: InterviewFilterType.nameFilter
  },
  {
    key: 'gender',
    label: 'gender',
    filterType: InterviewFilterType.containsTextFilter
  },
  {
    key: 'city',
    label: 'city',
    filterType: InterviewFilterType.containsTextFilter
  },
  { key: 'address', label: 'address' },
  {
    key: 'email',
    label: 'email',
    filterType: InterviewFilterType.containsTextFilter
  },
  {
    key: 'phone',
    label: 'phone_mob',
    filterType: InterviewFilterType.containsTextFilter
  }
]

export const defaultPageSize = PAGINATION_LIMIT
export const patientFilterFields = ['firstName', 'lastName', 'gender', 'birthDate', 'email', 'phone']

export const defaultColumnWidth = 100

export const defaultPageSizeOptions: number[] = [20, 50, 100, 200, 500, 1000]

export const defaultInterviewsTablePreferences: TablePreferences = {
  columns: [
    {
      columnName: 'surveyDate',
      position: 0,
      width: 100
    },
    {
      columnName: 'patient',
      position: 1,
      width: 150
    },
    {
      columnName: 'reasonOfVisit',
      position: 2,
      width: 150
    },
    {
      columnName: 'status',
      position: 3,
      width: 50
    },
    {
      columnName: 'actions',
      position: 4,
      width: 200
    },
    {
      columnName: 'alerts',
      position: 5,
      width: 100
    }
  ]
}

export const defaultPatientsTablePreferences: TablePreferences = {
  columns: [
    {
      columnName: 'birthDate',
      position: 0,
      width: 100
    },
    {
      columnName: 'name',
      position: 1,
      width: 150
    },
    {
      columnName: 'city',
      position: 2,
      width: 150
    },
    {
      columnName: 'address',
      position: 3,
      width: 150
    },
    {
      columnName: 'email',
      position: 4,
      width: 150
    },
    {
      columnName: 'phone',
      position: 5,
      width: 150
    }
  ]
}

export enum LoaderKey {
  changeHcs = 'changeHcs',
  contactByEmail = 'contactByEmail',
  contactBySms = 'contactBySms',
  continueSurvey = 'continueSurvey',
  patientInfo = 'getPatientInfo',
  refreshInterviewsData = 'refreshInterviewsData',
  refreshPatientsData = 'refreshPatientsData',
  resetPassword = 'resetPassword',
  saveUserInfo = 'saveUserInfo',
  submitDifferentialDiagnosis = 'submitDifferentialDiagnosis',
  submitSurvey = 'submitSurvey',
  summary = 'getSummary',
  summaryPdf = 'getSummaryPdf',
  summaryPlainText = 'getSummaryPlainText',
  translation = 'getTranslation',
  updatePatientData = 'updatePatientData'
}

export const sessionStorageSelectedSurveyKey = `_selected_entry_params_${ViewName.interviews}`

export const interviewsDefaultSort: ActiveSort = {
  sortColumnName: 'surveyDate',
  sortOrder: SortOrderLegacy.descending
}

export const patientsDefaultSort: ActiveSort = {
  sortColumnName: 'none',
  sortOrder: SortOrderLegacy.none
}

export const EMPTY_SORT: ActiveSort = {
  sortColumnName: '',
  sortOrder: SortOrderLegacy.none
}

export const CARE_EPISODE_COMMUNICATION_TYPES: {
  [key in CareEpisodeCommunicationType]: string
} = {
  email: 'l_email'
}
