import {
  defaultCountryPhoneCode,
  defaultLocale,
  localeUS,
  phoneCodeUS,
} from '@shared/data/constants';

export const getDateFormatString = (locale: string | undefined) => {
  const options: any = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  };

  const formatObj = new Intl.DateTimeFormat(locale, options).formatToParts(
    Date.now()
  );

  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case 'day':
          return 'DD';
        case 'month':
          return 'MM';
        case 'year':
          return 'YYYY';
        default:
          return obj.value;
      }
    })
    .join('');
};

export const getCountriesPhoneInformation = (
  countryPhoneCode = defaultCountryPhoneCode
) => {
  return countryPhoneCode === phoneCodeUS ? localeUS : defaultLocale;
};
