import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GlobalLoaderService {
  public loadingKeys = new BehaviorSubject<Set<string>>(new Set<string>());
  public isLoading$ = this.loadingKeys.pipe(map((keys) => keys.size > 0));

  public startLoading(key: string): void {
    const updatedKeys = new Set<string>([...this.loadingKeys.value, key]);
    this.loadingKeys.next(updatedKeys);
  }

  public stopLoading(key: string): void {
    const updatedKeys = this.loadingKeys.value;
    if (updatedKeys.has(key)) updatedKeys.delete(key);
    this.loadingKeys.next(updatedKeys);
  }

  public isLoadingByKey(key: string) {
    return this.loadingKeys.pipe(map((keys) => keys.has(key)));
  }
}
