export enum ViewName {
  interviews = 'Interviews',
  patients = 'Patients'
}

/* To be filled with possible variants that can be returned by the backend */
export enum InterviewStatus {
  new = 'status_new',
  inProgress = 'status_inProgress',
  finished = 'status_finished',
  locked = 'status_locked',
  lockIncomplete = 'status_lock_incomplete',
  exported = 'status_exported',
  underReview = 'status_under_review',
  archived = 'status_archived'
}

/* To be filled with possible variants that can be returned by the backend */
export enum AlertType {
  type_1 = 'alert_type_1',
  type_2 = 'alert_type_2',
  type_3 = 'alert_type_3'
}

export enum ActionType {
  toContact = 'action_tocontact',
  agenda = 'action_agenda',
  refer = 'action_refer',
  rx = 'action_rx',
  voicemail = 'action_voicemail',
  homeCare = 'action_home_care',
  archive = 'action_archive'
}

export enum PatientColumns {
  LastName = 'lastName',
  FirstName = 'firstName',
  Gender = 'gender',
  BirthDate = 'birthDate',
  Email = 'email',
  Phone = 'phone'
}
