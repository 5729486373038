interface IApiRequest<T> {
  data: T;
}

export class ApiRequest<T> implements IApiRequest<T> {
  data: T;

  constructor(data: T) {
    this.data = data;
  }
}
